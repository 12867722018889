import React, {useEffect} from 'react';
import shallow from 'zustand/shallow';
import Helmet from 'react-helmet';
import {Link} from 'gatsby';
import Layout from '../components/Layout';
import {usePhotoStore} from '../store';

const RenderProjects = () => {
  const {projectsInfo, setCurrentProject, getProjectsInfo} = usePhotoStore(
    (state) => ({
      projectsInfo: state.projectsInfo,
      setCurrentProject: state.setCurrentProject,
      getProjectsInfo: state.getProjectsInfo,
    }),
    shallow
  );

  useEffect(() => {
    let mounted = true;

    if (mounted && !projectsInfo) {
      getProjectsInfo();
    }

    return () => {
      mounted = false;
    };
  }, [getProjectsInfo, projectsInfo]);

  return (
    projectsInfo &&
    projectsInfo.map((projectItem) => (
      <section key={projectItem.projectName} id={projectItem.projectName}>
        <Link
          className="image"
          to={`/project?=${projectItem.projectName}`}
          onClick={() => setCurrentProject(projectItem)}>
          <img src={projectItem.firstProjectPhoto} alt={projectItem.projectName} />
        </Link>
        <div className="content">
          <div className="inner">
            <header className="major">
              <h3>{projectItem.projectTitle || ''}</h3>
            </header>
            <ul className="actions">
              <li>
                <Link
                  className="button"
                  to={`/project?=${projectItem.projectName}`}
                  onClick={() => setCurrentProject(projectItem)}>
                  Learn more
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </section>
    ))
  );
};

const Projects = () => (
  <Layout>
    <Helmet
      title="Projects Page"
      meta={[
        {
          name: 'description',
          content: 'Projects Page',
        },
      ]}
    />
    <div>
      <div id="main">
        <section id="two" className="spotlights">
          <RenderProjects />
        </section>
      </div>
    </div>
  </Layout>
);

export default Projects;
